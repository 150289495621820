import React from 'react'
import { ImageType } from '@utils/types'
import Image from '@ui/image'
import PropTypes from 'prop-types'

const ClientCard = ({ image, name, path, target, ...rest }) => {
	return (
		<div className='main-content' {...rest}>
			<div className='inner text-center'>
				{image?.src && (
					<div className='thumbnail'>
						<a href={path} target='_blank' rel='noreferrer'>
							<Image src={image.src} alt={image?.alt || name} />
						</a>
					</div>
				)}
				<div className='seperator'></div>
				<div className='client-name'>
					<span>
						<a target='_blank' rel='noreferrer' href={path}>
							{name}
						</a>
					</span>
				</div>
			</div>
		</div>
	)
}

ClientCard.propTypes = {
	image: PropTypes.shape(ImageType).isRequired,
	name: PropTypes.string.isRequired,
	path: PropTypes.string,
	target: PropTypes.oneOf(['_blank', '_self', '_parent', '_top'])
}

ClientCard.defaultProps = {
	path: '#!',
}

export default ClientCard
