import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { normalizedData } from '@utils'
import Layout from '@layout'
import Header from '@layout/header'
import Footer from '@layout/footer'
import HeroArea from '@containers/hero/layout-01'
import ServicesArea from '@containers/service/layout-01'
import PortfolioArea from '@containers/portfolio/layout-01'
import ResumeArea from '@containers/resume/layout-01'
import ClientArea3 from '@containers/client/layout-03'
import BlogArea from '@containers/blog/layout-01'
import ContactArea from '@containers/contact/layout-01'
import EducationArea from '@containers/education/layout-01'
import SkillArea from '@containers/skill/layout-01'
import ExperienceArea from '@containers/experience/layout-01'
import InterviewArea from '@containers/interview/layout-01'

const IndexPage = ({ data }) => {
	const content = normalizedData(data?.homePage?.content || [])

	return (
		<Layout pageTitle='Welcome' className='white-version'>
			<Header
				data={{
					...data.header,
					...data.navigation,
					socials: data.site.siteMetadata.socials,
				}}
			/>
			<main className='main-page-wrapper'>
				<HeroArea
					data={{
						...content['hero-section'],
						socials: data.site.siteMetadata.socials,
					}}
				/>
				<ResumeArea data={content['resume-section']}>
					<EducationArea data={content['education-section']} />
					<SkillArea data={content['skill-section']} />
					<ExperienceArea data={content['experience-section']} />
					<InterviewArea data={content['interview-section']} />
				</ResumeArea>
				<ServicesArea data={content['service-section']} />
				<PortfolioArea data={content['portfolio-section']} />

				{/* <TestimonialArea data={content['testimonial-section']} /> */}
				{/* <ClientArea data={content['client-section']} /> */}
				<ClientArea3 data={content['publish-section']} />
				<ClientArea3 data={content['membership']} id={'memberships'} />
				{/* <PricingArea data={content['pricing-section']} /> */}
				<BlogArea
					id={'projects'}
					data={{
						...content['blog-section'],
						blogs: data?.allArticle?.nodes,
					}}
				/>
				<ContactArea
					data={{
						...content['contact-section'],
						socials: data.site.siteMetadata.socials,
						phone: data.site.siteMetadata?.contact?.phone,
						email: data.site.siteMetadata?.contact?.email,
						getform_url: data.site.siteMetadata?.getform_url,
					}}
				/>
			</main>
			<Footer data={{ ...data.footer }} className='section-separator' />
		</Layout>
	)
}

export const query = graphql`
	query DefaultWhitePageQuery {
		site {
			...Site
		}
		header: general(section: { eq: "header-1-white" }) {
			...Header01
		}
		navigation: general(section: { eq: "menu-1" }) {
			menu {
				...Menu01
			}
		}
		footer: general(section: { eq: "footer-1-white" }) {
			...Footer01
		}
		homePage(title: { eq: "default-white-home" }) {
			content {
				...Content01
			}
		}
		allArticle(sort: { fields: date, order: ASC }) {
			nodes {
				...Article
			}
		}
	}
`

IndexPage.propTypes = {
	data: PropTypes.shape({
		site: PropTypes.shape({
			siteMetadata: PropTypes.shape({
				socials: PropTypes.arrayOf(PropTypes.shape({})),
				contact: PropTypes.shape({
					phone: PropTypes.string,
					email: PropTypes.string,
				}),
				getform_url: PropTypes.string,
			}),
		}),
		homePage: PropTypes.shape({
			content: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		allArticle: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.shape({})),
		}),
		navigation: PropTypes.shape({}),
		header: PropTypes.shape({}),
		footer: PropTypes.shape({}),
	}),
}

export default IndexPage
